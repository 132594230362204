<template>
<div>
    <div v-if=!loading.profile class="profile">
        <img v-if=profile.picture v-bind:src="'/server/1900eab6c028483d7126599ee6f50de0d27907b5c65fa90524580b4b0f9852b/'+profile.picture" class="picture" />
        <h3 class="name">{{profile.user.firstName}} {{profile.user.lastName}}</h3>
        <h6><i>Accolades</i></h6>
        <div v-if=profile.accolades.length>
            <b-badge v-for="accolade in profile.accolades" :key="accolade.id" v-bind:style="{ border: '1px solid ' + accolade.color, marginRight: '5px'}">{{accolade.season.year}}: {{accolade.title}}</b-badge>
        </div>
        <div v-else>
            <p><i>No accolades, what a loser...</i></p>
        </div>
    </div>

    <hr>

    <b-card no-body>
      <b-card-header style="text-align: center">Money Projection</b-card-header>
      <b-card-body>
        <div>
          <label for="range-1">Bet Input ($1-$100)</label>
          <b-form-input id="range-1" v-model="value" type="range" min="1" max="100"></b-form-input>
          <div class="mt-2">Risking: ${{ value }} per bet</div>
          <div class="mt-2">Earnings: ${{ (value*funnymoney) }}</div>
          <div class="mt-2" style="font-size: 10px">** Earnings are calculated based on placing a $1 bet on each of the money line, spread, and total. The slider will scale appropriately.</div>
        </div>
      </b-card-body>
    </b-card>


    <div style="margin-top:30px">
        <b-card no-body v-if=!loading.graph>
        <b-card-header style="text-align: center">Weekly Progression</b-card-header>
        <b-card-body>
            <!-- <div v-if="!loading"> -->
            <user-graph :labels=labels :data=data />
            <!-- </div>
            <div v-else>
            <div class="text-center">
                <b-spinner variant="danger" label="Loading..."></b-spinner>
            </div>
            </div> -->
        </b-card-body>
        </b-card>
    </div>
    
    <!-- {{data}} -->

    <!-- {{profile}} -->
</div>
  <!-- <b-card no-body>
    <b-card-header>PROFILE</b-card-header>
    <b-card-body>

    </b-card-body>
  </b-card> -->
</template>

<script>
import { api } from "@/utils/api";
import UserGraph from "@/components/graphs/UserGraph";

export default {
  name: "Profile",
  components: {
    UserGraph
  },
  data() {
    return {
        profile: "",
        loading: {
            profile: true,
            graph: true
        },
        labels: null,
        data: null,
        funnymoney: 0,
        value: 1
    };
  },
  created() {
    let self = this;
    api(
      `query { profile(id: "${this.$route.params.id}") { picture user { firstName lastName } accolades { id season { year } title color } } funnymoney(id: "${this.$route.params.id}") }`
    ).then((data) => {
      self.loading.profile = false;
      self.profile = data.profile;
      self.funnymoney = data.funnymoney
    });
    api(`mutation{ usergraph(pid: "${this.$route.params.id}"){ code message labels data } }`).then(data => {
      self.labels = JSON.parse(data.usergraph.labels);
      self.data = JSON.parse(data.usergraph.data);
      self.loading.graph = false;
    });
  }
};
</script>

<style scoped>
.profile {
    margin-top: 15px;
    text-align: center;
}
.profile > .picture {
  width:200px;
  height:200px;
  object-fit:cover;
  border-radius:50%;
}
.profile > .name {
  margin-top: 10px;
  object-fit:cover;
  border-radius:50%;
}

</style>