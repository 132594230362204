// CommitChart.js
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    labels: null,
    data: null
  },
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: this.labels,
        datasets: this.data
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        scales: {
          yAxes: [
            {
              stacked: true
            },
            {
              ticks: {
                stepSize: 3
              }
            }
          ],
          xAxes: [{ stacked: true }],
        },
        annotation: {
          annotations: [
            {
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: "3",
              borderColor: "tomato",
              borderWidth: 1
            }
          ],
          drawTime: "afterDraw" // (default)
        }
      }
    );
  }
};
